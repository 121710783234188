<template>
  <!-- Breadcrumbs -->
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <!-- Title -->
  <teleport-wrapper to="#mainTitle">
    {{ t('ttmt.excursions.index.your_excursions') }}

    <span v-if="dashboardHotelierAccess">
      -
      {{ t('ttmt.excursions.index.read_only') }}
    </span>
  </teleport-wrapper>

  <!-- Help -->
  <teleport-wrapper to="#help">
    <tutorial-excursion-index />
  </teleport-wrapper>

  <div>
    <app-alert
      v-if="dashboardHotelierAccess"
      design="info"
      :title="t('ttmt.excursions.index.alert.title')"
      class="mb-6"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="t('ttmt.excursions.index.alert.content')" />
    </app-alert>

    <div class="mb-5 flex justify-between items-start md:items-center flex-col md:flex-row max-w-sm md:max-w-full">
      <!-- Search -->
      <form-control-text
        name="excursions_search"
        :placeholder="t('ttmt.excursions.index.search')"
        :icon-props="{ library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' }"
        class="max-w-sm w-full flex-1 mb-3 md:mb-0"
        @changed="handleSearch"
      />

      <!-- Creation -->
      <app-button
        v-if="store.state.auth.user.authorizations.excursions.create"
        feature="create"
        :label="t('ttmt.extranet.excursions.actions.new')"
        class="w-full md:w-fit"
        @click="displayCreationModal"
      />
    </div>

    <!-- Filters -->
    <app-filters
      class="my-4"
      :display-filters="hasActiveFilters"
    >
      <excursion-filters
        @filters-applied="handleFiltersApplied"
      />
    </app-filters>

    <!-- Results -->
    <div>
      <app-table
        :headers="headers"
        :data="tableData"
        :loading="loading"
      />

      <app-pagination
        :links="links"
        :change-page-callback="handleChangePage"
        class="mt-5"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
  inject,
} from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  fetchExcursions as fetchApiExcursions,
  destroyExcursion,
} from '@shared/http/api'

import breadcrumbExcursion from '@extranet/breadcrumbs/excursion'
import useAccess from '@extranet/hooks/access'
import useFilters from '@shared/hooks/filters'
import useForm from '@shared/hooks/form/form'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppFilters from '@extranet/components/ui/AppFilters.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import ExcursionFilters from '@extranet/components/resources/excursion/ExcursionFilters.vue'
import ExcursionTableActions from '@extranet/components/resources/excursion/ExcursionTableActions.vue'
import ExcursionWithMainPicture from '@extranet/components/resources/excursion/ExcursionWithMainPicture.vue'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import TutorialExcursionIndex from '@extranet/components/tutorials/excursion/TutorialExcursionIndex.vue'

const props = defineProps({})

defineEmits([
  'destroyed',
])

const store = useStore()
const { t } = useI18n()

// ---------- EXCURSIONS ----------

const nextPageFetching = ref(false)
const excursions = ref([])
const loading = ref(false)

function fetchExcursions(nextPage = false) {
  nextPageFetching.value = nextPage
  loading.value = true

  const params = {
    'page': page.value,
    'search': searchKeywords.value,
    'mode': 'index',
    'source': 'extranet',
    'filters': allFieldsToSubmit.value,
  }

  fetchApiExcursions(params)
    .then((response) => {
      const oldExcursions = excursions.value
      const newExcursions = response.data.data

      if (nextPage) {
        // Add new results after the old ones
        excursions.value = [...oldExcursions, ...newExcursions]
      } else {
        // Replace the old results with the new ones
        excursions.value = newExcursions
      }

      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

function handleDestroy(id) {
  destroyExcursion(id).then(() => {
    resetPage()
    fetchExcursions()
    store.commit(
      'flashes/ADD_FLASH',
      { message: t('ttmt.excursions.destroy.success'), type: 'success' },
    )
  })
}

// ---------- SEARCH ----------

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }

  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    fetchExcursions()
  }, 400)
}

// ---------- DATA TABLE ----------

const headers = [
  { label: t('validation.attributes.title') },
  { label: t('validation.attributes.kind') },
  { label: t('validation.attributes.points_of_interest_count') },
  { label: t('validation.attributes.actions') },
]

const tableData = computed(() => (
  excursions?.value?.map((excursion) => ({
    title: {
      component: ExcursionWithMainPicture,
      props: {
        resource: excursion,
      },
    },
    kind: t(`enums.App\\Enums\\Excursion\\KindEnum.${excursion.attributes.kind}`),
    points_of_interest_count: excursion.attributes.points_of_interest_count,
    actions: {
      component: ExcursionTableActions,
      props: {
        resource: excursion,
        destroyCallback: () => handleDestroy(excursion.id),
      },
    },
  }))
))

// ---------- PAGINATION ----------

const links = ref([])
const page = ref(1)

function resetPage() {
  page.value = 1
}

function handleChangePage(value) {
  page.value = value
  fetchExcursions()
}

// ---------- FILTERS ----------

const {
  allFieldsToSubmit,
} = useForm(props, {
  initialValues: store.state.filters.excursions,
  formName: 'excursionsIndexFiltersForm',
})

const {
  hasActiveFilters,
} = useFilters('excursions')

function handleFiltersApplied() {
  resetPage()
  fetchExcursions()
}

// ---------- CREATION ----------

function displayCreationModal() {
  // Display the creation modal
  store.commit('modals/SHOW', 'excursion_form')
}

// ---------- BREADCRUMBS ----------

const { index } = breadcrumbExcursion()

const breadcrumbs = computed(() => (
  index()
))

// ---------- ACCESS ----------

const {
  dashboardHotelierAccess,
} = useAccess()

// ---------- LIFECYCLES ----------

onMounted(() => {
  // Filters' form-groups are rendered in child component
  // So must wait onMounted lifecycle hook to fetch resources with filters
  fetchExcursions()
})

onUnmounted(() => {
  store.commit('filters/RESET_FILTERS', 'excursions')
})

// ---------- REFRESH INDEX ----------

const excursionsIndexRefresh = inject('excursions_index_refresh')

watch(excursionsIndexRefresh, () => {
  resetPage()
  fetchExcursions()
})
</script>

<template>
  <p class="font-bold text-xl mb-4">
    {{ t('ttmt.tutorials.extranet.excursions.filters.title') }}
  </p>

  <p class="font-bold">
    {{ t('ttmt.tutorials.extranet.excursions.filters.subtitle') }}
  </p>

  <ul class="mt-4 list-inside">
    <!-- eslint-disable vue/no-v-html -->
    <li v-html="t('ttmt.tutorials.extranet.excursions.filters.distance')" />

    <!-- eslint-disable vue/no-v-html -->
    <li v-html="t('ttmt.tutorials.extranet.excursions.filters.type.explanation')" />

    <ul class="ml-4">
      <!-- eslint-disable vue/no-v-html -->
      <li v-html="t('ttmt.tutorials.extranet.excursions.filters.type.customized')" />

      <!-- eslint-disable vue/no-v-html -->
      <li v-html="t('ttmt.tutorials.extranet.excursions.filters.type.root')" />

      <!-- eslint-disable vue/no-v-html -->
      <li v-html="t('ttmt.tutorials.extranet.excursions.filters.type.ugozer')" />

      <!-- eslint-disable vue/no-v-html -->
      <li v-html="t('ttmt.tutorials.extranet.excursions.filters.type.to')" />
    </ul>
  </ul>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
